import { normalizeBlockContentNodes } from '@blocks-helpers'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Divider from '@solid-ui-components/Divider'
import Seo from '@solid-ui-components/Seo'
import Layout from '@solid-ui-layout/Layout'
import { graphql } from 'gatsby'
import React from 'react'
import { Container } from 'theme-ui'
import styles from './_styles'
import theme from './_theme'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header-light']} menuJustify='space-between' />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      {/* <Container id='why' variant='wide' sx={styles.tabsContainer}>
        <Tabs space={5}>
          <FeatureOne id="engineers" content={content['tab-feature-one']} />
          <FeatureTwo id="leaders" content={content['tab-feature-two']} />
          <FeatureThree content={content['tab-feature-three']} />
        </Tabs>
      </Container> */}
      {/* <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Container id="features" variant='wide' sx={styles.tabsContainer}>
        <Tabs space={3} variant='dots' position='bottom' arrows>
          <Screenshot content={content['screenshot-one']} />
          <Screenshot content={content['screenshot-two']} />
          <Screenshot content={content['screenshot-three']} />
        </Tabs>
      </Container> */}
      {/* <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Process content={content['process']} />
      <Divider space='5' />
      <Container id="how" variant='full' sx={styles.featuresContainer}>
        <Features content={content['features']} />
      </Container> */}
      {/* <Divider space='5' /> */}
      {/* <Container variant='full' sx={styles.socialProofContainer}>
        <Divider space='5' />
        <Divider space='5' />
        <Container variant='narrow'>
          <Stats content={content['stats']} />
          <Divider space='5' />
        </Container>
        <Testimonials content={content['testimonials']} />
        <Divider space='5' />
      </Container> */}
      {/* <Divider space='5' />
      <Divider space='5' />
      <Pricing id="pricing" content={content['pricing']} /> */}
      <Divider space='5' />
      {/* <Companies content={content['companies']} />
      <Divider space='5' /> */}
      {/* <Container id="faq" variant='narrow'>
        <Faq content={content['faq']} />
      </Container>
      <Divider space='5' /> */}
      {/* <Divider space='5' />
      <Blog content={content['latest-blogs']} />
      <Divider space='5' /> */}
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageBlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
